export const socialsData = {
    github: 'https://github.com/',
    // facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/megha-sonkusare-a1456513b/',
    // instagram: 'https://www.instagram.com/',
    // codepen: 'https://codepen.io/',
    // twitter: 'https://twitter.com/',
    // reddit: 'https://www.reddit.com/user/',
    blogger: 'https://www.blogger.com/u/2/blog/posts/4813218583946680008?pli=1',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',
    gitlab: 'https://gitlab.com/',
    // youtube: 'https://youtube.com/'
}