import snapDev from "../assets/png/snapDev.png";
import snapCerti from "../assets/png/snapCerti.png";
import cloudEngg from "../assets/png/cloudEngg.png"



export const achievementData = {
    bio : "Unlocking Potential, Embracing Excellence: Showcasing a unfolds as a testament to remarkable achievements and continuous growth.",
    achievements : [
        {
            id : 1,
            title : 'Google Cloud Associate Cloud Engineer certification',
            details : 
            'Earning the Google Cloud Associate Cloud Engineer certification demonstrates a proven proficiency in deploying and managing applications on Google Cloud Platform, showcasing foundational cloud skills and knowledge of key GCP services.',
            date : 'Nov 20, 2023',
            field : 'IT',
            image : cloudEngg
        },
        {
            id : 2,
            title : 'SnapLogic Beginner Program',
            details : 'Validate technical skills and cloud expertise to grow your career and business.',
            date : 'Dec 5, 2023',
            field : 'IT',
            image : snapDev
        },
        {
            id : 3,
            title : 'SnapLogic Integrator Certification',
            details : 'Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.',
            date : 'Dec 15, 2023',
            field : 'IT',
            image : snapCerti
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/