import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Monet 2.0',
        projectDesc: 'Create visually captivating applications with Monet rich visualization capabilities, including maps, charts, and graphs, for engaging user experiences.',
        tags: ['Vue JS', 'Grapesjs', 'HTML', 'CSS', 'Javascript'],
        code: 'https://dev-monet.gaiansolutions.com/',
        demo: 'https://dev-monet.gaiansolutions.com/',
        image: one
    },
    {
        id: 2,
        projectName: 'Pascal Intelligence',
        projectDesc: 'PI-Pascal Intelligence is an advanced analytics platform that helps organizations unlock the full potential of their data. It provides powerful insights and predictive analytics to help businesses make better decisions and drive growth',
        tags: ['Angular', 'HTML', 'SCSS', 'Typescript', 'Javascript', 'Threejs', 'Graphql', 'Neo4j'],
        code: 'https://aidtaas.com/platform/pascal-intelligence',
        demo: 'https://aidtaas.com/platform/pascal-intelligence',
        image: two
    },
    {
        id: 3,
        projectName: 'MSM UNIFY',
        projectDesc: `MSM Unify is an online platform that accelerates agent base growth
        while invigorating efforts for international student enrollment. It
        automates the agents recruitment processes and streamlines the
        management of student profiles. Students and agents will also get
        access to MSM’s network of prestigious institutions.`,
        tags: ['Angular 14', 'Material UI', 'HTML', 'SCSS', 'Typescript', 'Javascript', 'DevOps'],
        code: 'https://www.msmunify.com/agent-prospect/',
        demo: 'https://www.msmunify.com/agent-prospect/',
        image: three
    },
    {
        id: 4,
        projectName: 'MSM LMS',
        projectDesc: `MSM UNIFY Learning Management System. MSM LMS is an international
        education marketplace that connects international students with higher
        education institutions and recruiting partners worldwide.`,
        tags: ['Html5', 'SCSS', 'Javascript', 'TypeScript', 'Angular material', 'Angular 13'],
        code: 'https://lms-frontendangular.azurewebsites.net/login',
        demo: 'https://lms-frontendangular.azurewebsites.net/login',
        image: four
    },
    {
        id: 5,
        projectName: 'WizHob',
        projectDesc: `WizHob is a learning platform to help learn hobbies and to get skilled at life.
        This platform offers Wizpaz subscription passes which allows users to try or
        attend online classes or join hobby classes at nominal rates`,
        tags: ['Html5', 'SCSS', 'Bootstrap', 'Javascript', 'TypeScript', 'NodeJS', 'MongoDB', 'Express'],
        code: 'https://wizhob.com/',
        demo: 'https://wizhob.com/',
        image: five
    },
    {
        id: 6,
        projectName: 'Developer Portfolio',
        projectDesc: 'Developer-portfolio clone',
        tags: ['ReactJs','Material UI', 'CSS', 'NodeJs', 'ExperessJs' ],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: six
    },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/