export const experienceData = [
    {
        id: 1,
        jobtitle: 'Software Engineer (MTS-2)',
        company: 'Gaian Solutions Pvt. Ltd. India',
        description: ``,
        startYear: '24th April 2023',
        endYear: 'Present'
    },
    {
        id: 2,
        jobtitle: 'Angular Developer',
        company: 'MSM Global Education Pvt. Ltd. (Ahmadabad)',
        startYear: '2nd March 2022',
        endYear: '23rd Dec 2022'
    },
    {
        id: 3,
        jobtitle: 'Web Designer & Developer',
        company: 'ASIT Solutions Pvt. Ltd.- Nagpur(Maharashtra)',
        startYear: '9th Dec 2019',
        endYear: '18th Feb 2022'
    },
]