import resume from '../assets/pdf/Megha_Angular devloper_UI (2023).pdf'
import a from "../assets/profile.jpeg"

export const headerData = {
    name: 'Meghaa G. Sonkusare',
    title: "FrontEnd Web Developer",
    desciption:"As a Frontend Web Developer, I am the architect of user experiences, transforming ideas and designs into visually stunning and seamless digital interfaces. With a passion for crafting responsive and dynamic websites, I specialize in the art of code, weaving together HTML, CSS, and JavaScript to breathe life into creative visions. Join me on the journey of turning concepts into captivating digital realities.",
    image: a,
    resumePdf: resume
}
