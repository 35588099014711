import React, { createContext,useRef, useState } from "react";

import { themeData } from "../data/themeData";

export const ThemeContext = createContext();

function ThemeContextProvider(props) {
  // eslint-disable-next-line
  const [themeIdx,setThemeIdx] = useState(0)
  const [theme, setTheme] = useState(themeData.theme[3]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dropDownOpen,setOpenDropdown] = useState(false);

  const setHandleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  themeData.theme = themeData.theme.map((theme, i) => {
    return { ...theme, name: `theme-${i}` };
  });

  const handleThemeIdx = (value) => {
    setThemeIdx(parseInt(value.split("-")[1]))
    setTheme(themeData.theme[themeIdx])

  };


  const handleNavLinkClick = () => {
    setOpenDropdown(!dropDownOpen);

    console.log(dropDownOpen);

    // // Programmatically focus and open the dropdown
    // setTimeout(() => {
    //   selectRef.current.focus();
    // }, 0);
  };

  const value = {
    theme,
    drawerOpen,
    setHandleDrawer,
    allthemes: themeData.theme,
    handleThemeIdx,
    themeIdx,
    handleNavLinkClick,
    dropDownOpen
  };
  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
