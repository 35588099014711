export const educationData = [
    {
        id: 1,
        institution: 'Bachelor of Technology',
        course: 'Computer Science and Engineering',
        startYear: '2015',
        endYear: '2018'
    },
    {
        id: 2,
        institution: 'Diploma of Technology',
        course: 'Computer Engineering',
        startYear: '2012',
        endYear: '2015'
    },
    {
        id: 3,
        institution: 'Class X',
        course: 'Siddheshwar High School',
        startYear: '2001',
        endYear: '2011'
    },
]